<template>
  <div>
    <vs-button class="mb-2" @click="showPopup()">
      {{ $t('search.synonyms.addNew') }}
    </vs-button>
    <vs-popup
      class="synonymCreatePopup"
      :title="$t('search.synonyms.createPopupHeader')"
      :button-close-hidden="true"
      :active.sync="active"
    >
      <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
        <label>{{ $t('search.synonyms.createPopupSynonymsLabel') }}</label>
        <vs-row>
          <vs-col class="mb-4 w-full">
            <div v-for="(synonym, index) in synonyms" :key="index" ref="synonymInputContainer">
              <vs-row class="mb-1">
                <vs-col vs-w="11">
                  <vs-input
                    :key="index"
                    v-model="synonym.synonym"
                    class="w-100"
                    size="sm"
                    :danger="!synonym.isValid"
                    :danger-text="$t('search.synonyms.popupSynonymInvalid')"
                  />
                </vs-col>
                <vs-col v-if="isNotFirstSynonym(index)" vs-w="1" vs-type="flex" vs-justify="flex-end" vs-align="center">
                  <b-button
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click.prevent="removeSynonymInput(index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </vs-col>
              </vs-row>
              <div v-if="synonyms.length - 1 > index" class="text-center">
                {{ $t('search.synonyms.or') }}
              </div>
            </div>
            <div class="text-left">
              <b-button variant="primary" size="sm" @click.prevent="addSynonymInput()">
                <feather-icon icon="PlusCircleIcon" />
                {{ $t('search.synonyms.addSynonymInputTooltip') }}
              </b-button>
            </div>
          </vs-col>
        </vs-row>
        <label>{{ $t('search.synonyms.createPopupBaseWordLabel') }}</label>
        <vs-row>
          <vs-col class="mb-5 w-100">
            <vs-input
              v-model="baseWord" class="w-100"
              :danger="!validBaseWord"
              :danger-text="$t('search.synonyms.popupBaseWordInvalid')"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="2" vs-offset="8">
            <b-button variant="primary" @click="save()">
              {{ $t('search.synonyms.editPopupSave') }}
            </b-button>
          </vs-col>
          <vs-col vs-w="2">
            <b-button variant="outline-primary" @click="hidePopup()">
              {{ $t('search.synonyms.editPopupCancel') }}
            </b-button>
          </vs-col>
        </vs-row>
      </b-overlay>
    </vs-popup>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'

const emptySynonym = { synonym: '', isValid: true }

export default {
  data() {
    return {
      active: false,
      baseWord: '',
      validBaseWord: true,
      synonyms: [emptySynonym],
      dataLoading: false,
    }
  },
  methods: {
    filterNonEmptySynonyms() {
      this.synonyms = this.synonyms.filter((synonym, index) => index === 0 || synonym.synonym !== '')
    },
    hidePopup() {
      this.active = false
      this.filterNonEmptySynonyms()
    },
    showPopup() {
      this.active = true
    },
    isNotFirstSynonym(index) {
      return index !== 0
    },
    addSynonymInput() {
      this.synonyms.push({ synonym: '', isValid: true })
    },
    removeSynonymInput(index) {
      this.synonyms.splice(index, 1)
    },
    save() {
      this.dataLoading = true

      this.filterNonEmptySynonyms()

      if (!this.validateSynonyms() || !this.validateBaseWord()) {
        this.validateBaseWord()
        this.dataLoading = false
        return
      }

      const requestData = {
        synonyms: this.synonyms.map(synonym => synonym.synonym),
        baseWord: this.baseWord,
      }
      apiRequest
        .post('search/synonyms', requestData)
        .then(() => {
          this.synonyms = [{ synonym: '' }]
          this.baseWord = ''
          this.dataLoading = false

          this.hidePopup()

          store.commit('notifications/addPopupInfo', {
            name: this.$t('search.synonyms.insertSuccessfulNotificationTitle'),
            message: this.$t('search.synonyms.insertSuccessfulNotification'),
          })

          this.$root.$emit('synonymCreated')
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
    validateSynonyms() {
      let allValid = true
      this.synonyms.forEach((synonym, index) => {
        allValid = allValid && synonym.synonym.length > 0
        this.synonyms[index].isValid = synonym.synonym.length > 0
      })
      return allValid
    },
    validateBaseWord() {
      this.validBaseWord = this.baseWord.length > 0
      return this.validBaseWord
    },
  },
}
</script>
