<template>
  <vs-row>
    <vs-col>
      <b-card :title="$t('search.synonyms.header')">
        <synonyms-create-popup />

        <synonyms-table
            api-endpoint="/search/synonyms"
            :columns="synonymsTable.columns"
            :default-sort="synonymsTable.defaultSort"
            :default-order-by="synonymsTable.defaultOrderBy"
        />
      </b-card>
    </vs-col>
  </vs-row>
</template>

<script>
import SynonymsTable from '@/views/components/search/Synonyms/SynonymsTable.vue'
import SynonymsCreatePopup from '@/views/components/search/Synonyms/SynonymsCreatePopup.vue'

export default {
  components: {
    SynonymsCreatePopup,
    SynonymsTable,
  },
  data() {
    return {
      synonymsTable: {
        defaultSort: 'asc',
        defaultOrderBy: 'synonym',
        columns: [
          {
            displayName: this.$t('search.synonyms.synonymColumnHeader'),
            name: 'synonym',
          },
          {
            displayName: this.$t('search.synonyms.baseWordColumnHeader'),
            name: 'baseWord',
          },
        ],
      },
    }
  },
}
</script>
